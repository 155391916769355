import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { postData } from "./actions";

// import { CoreHeadingBlock } from '../blocks/CoreHeadingBlock';
// import { CoreParagraphBlock } from '../blocks/CoreParagraphBlock';

import styles from "./Form.styles";

const useStyles = makeStyles((theme) => styles(theme));

function Form({ product }) {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState();
  const [company, setCompany] = useState("");
  const [postcode, setPostcode] = useState("");
  const [comments, setComments] = useState("");
  const [formLocked, lockForm] = useState(false);

  function handleSubmit(e) {
    if (e) e.preventDefault();

    const request = postData({
      forename: name,
      surname,
      email,
      telephone: tel,
      company,
      company_postcode: postcode,
      comments,
      product: `${product.title}/${product.product.code}${
        product.product.rrpPrice ? "/" + product.product.rrpPrice : ""
      }${product.product.tradePrice ? "/" + product.product.tradePrice : ""}${
        product.product.memberPrice ? "/" + product.product.memberPrice : ""
      }`,
    });

    request.then((res) => {
      if (res.status === 200 && res.data.success === true) {
        lockForm(true);
      }
    });
  }

  return (
    <div className="enquiry-form">
      {formLocked && (
        <p className={classes.success}>
          Thank you. A member of the team will get back to your shortly.
        </p>
      )}

      {!formLocked && (
        <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
          <fieldset>
            <input type="hidden" name="product" value={`${product.title}`} />

            <label htmlFor="name">First Name*</label>
            <input
              type="text"
              name="name"
              placeholder="John"
              required
              onChange={(e) => setName(e.target.value)}
            />

            <label htmlFor="surname">Last Name*</label>
            <input
              type="text"
              name="surname"
              placeholder="Smith"
              required
              onChange={(e) => setSurname(e.target.value)}
            />

            <label htmlFor="email">Email*</label>
            <input
              type="email"
              name="email"
              placeholder="example@gmail.com"
              required
              onChange={(e) => setEmail(e.target.value)}
            />

            <label htmlFor="phone">Phone Number*</label>
            <input
              type="tel"
              name="phone"
              placeholder="0778 8911 339"
              required
              onChange={(e) => setTel(e.target.value)}
            />

            <label htmlFor="company">Company Name</label>
            <input
              type="text"
              name="company"
              placeholder="Bosch"
              onChange={(e) => setCompany(e.target.value)}
            />

            <label htmlFor="postcode">Company Postcode</label>
            <input
              type="text"
              name="postcode"
              placeholder="SY7 9QS"
              onChange={(e) => setPostcode(e.target.value)}
            />

            <label htmlFor="comments">Comments</label>
            <textarea
              name="comments"
              placeholder="Comments here..."
              onChange={(e) => setComments(e.target.value)}
            />

            <input type="submit" value="Submit" id="EnquiryFormSubmit" />

            <p className="required">*Field is required</p>
          </fieldset>
        </form>
      )}
    </div>
  );
}

export default Form;
