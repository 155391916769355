import React, { Component } from "react";
import { graphql } from "gatsby";
import axios from "axios";
import { withStyles } from "@material-ui/styles";
import Layout from "../components/layout";
import Form from "../components/Enquiry/Form";
import Breadcrumb from "../components/Breadcrumb";
import { isBrowser, priceFormatter } from "../helpers";
import { isLoggedIn } from "../services/auth";

import { CoreHeadingBlock } from "../components/blocks/CoreHeadingBlock";

const styles = {
  enquiryPage: {
    marginTop: 40,
    "& .enquiry-wrap": {
      margin: "50px auto",
      "& .content, & .form": {
        padding: 32,
        backgroundColor: "#1b365d",
        flex: "0 0 100%",
        minWidth: "100%",
      },
      "@media (min-width: 960px)": {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "space-between",
        "& .content": {
          flex: "0 0 61.8%",
          minWidth: "61.8%",
          padding: 80,
        },
        "& .form": {
          flex: "0 0 calc(38.2% - 1px)",
          minWidth: "calc(38.2% - 1px)",
          padding: "80px 80px 80px 40px",
        },
      },
      "@media (min-width: 1280px)": {
        maxWidth: "87.5%",
      },
      "& .content": {
        "& .product-info": {
          backgroundColor: "white",
          boxShadow: "0 8px 16px rgba(0,0,0,0.16)",
          padding: 24,
          "@media (min-width: 960px)": {
            padding: "50px 40px",
          },
          "& h3": {
            margin: "0 0 36px",
            fontSize: 24,
            color: "#c8102e",
            letterSpacing: "0.016em",
            lineHeight: 1,
          },
          "& .product": {
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "space-between",
            "& img": {
              width: 80,
            },
            "& .placeholder": {
              backgroundColor: "#1b365d",
            },
            "& .product-info-link": {
              flex: "0 0 calc(100% - 80px)",
              maxWidth: "calc(100% - 80px)",
              paddingLeft: 12,
            },
            "& .product-info-block": {
              "@media (min-width: 960px)": {
                position: "relative",
                paddingRight: 80,
                display: "block",
              },
              "& .title, & .price": {
                fontFamily: "Bebas Neue",
                lineHeight: 1,
                fontSize: 24,
                color: "#1b365d",
                display: "block",
                letterSpacing: "0.016em",
              },
              "& .code": {
                color: "black",
                fontFamily: "Bebas Neue",
                fontSize: 16,
                letterSpacing: "0.016em",
                lineHeight: 1.5,
                display: "block",
                marginTop: 5,
              },
              "& .price": {
                "@media (min-width: 960px)": {
                  position: "absolute",
                  top: 0,
                  right: 0,
                  "& span": {
                    display: "none",
                  },
                },
              },
            },
          },
        },
        "& .product-details p": {
          color: "white",
          fontSize: 16,
          lineHeight: 1.5,
          letterSpacign: "0.016em",
          margin: "32px 0",
        },
      },
    },
  },
  detailsBlock: {},
};

const queryObject = (param) => ({
  query: `
  {
    product(id: "${param}") {
      id
      title
      product {
        code
        description
        memberPrice
        rrpPrice
        tradePrice
        productGallery {
          image
        }
      }
    }
  }
  `,
});

function Product({ product, group }) {
  if (!product || !product.product) return null;
  const { title } = product;

  const {
    description,
    memberPrice,
    rrpPrice,
    tradePrice,
    productGallery,
    code,
  } = product.product;

  return (
    <div>
      <div className="product-info">
        <h3>Details</h3>
        <div className="product">
          {productGallery.length > 0 && (
            <div className="product-image">
              <img src={productGallery[0].image} alt={title} />
            </div>
          )}
          <div className="product-info-link">
            <span className="product-info-block">
              <span className="title">{title}</span>
              <span className="code">{code}</span>
              {(group === "trade" || group === "member") && (
                <span className="price">
                  <span>Unit Price: </span>
                  {!group && priceFormatter(rrpPrice)}
                  {group === "trade" && priceFormatter(tradePrice)}
                  {group === "member" && priceFormatter(memberPrice)}
                </span>
              )}
            </span>
          </div>
        </div>
      </div>

      <div
        className="product-details"
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
    </div>
  );
}

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      group: null,
      authenticated: undefined,
    };

    if (!this.props.location.state) return;

    isBrowser &&
      this.state.authenticated === undefined &&
      this.handleAuthentication();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.authenticated !== this.state.authenticated) {
      this.fetchProduct();
    }
  }

  fetchProduct() {
    const id = this.props.location.state.product.id;
    if (!id) return null;

    axios
      .post(`${process.env.GATSBY_ADMIN_URL}graphql`, queryObject(id), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const data = res.data.data.product;

        if (!this.state.group) {
          data.product.rrpPrice = 0;
          data.product.tradePrice = 0;
          data.product.memberPrice = 0;
        }

        if (this.state.group === "trade") {
          data.product.memberPrice = 0;
        }

        this.setState({ data });
      });
  }

  async handleAuthentication() {
    const response = await isLoggedIn();

    if (response.data.errors) {
      this.setState({
        authenticated: false,
      });
    } else {
      this.setState({
        authenticated: response.data.data.user,
      });

      const group = response.data.data.user.userGroup.group.slug;

      if (group) {
        this.setState({
          group,
        });
      }
    }
  }

  render() {
    const product = this.state.data;

    return (
      <Layout
        meta={{
          ...this.props.data.wpPage.Meta,
          canonical: "/enquiry/",
        }}
        path={"/enquiry/"}
        cta={"a"}
        themeOptions={this.props.data.wp.themeOptions}
      >
        <Breadcrumb
          type="page"
          current={{
            title: "Enquiry",
            slug: "enquiry",
            uri: "/enquiry",
          }}
        />
        <div className={this.props.classes.enquiryPage}>
          <CoreHeadingBlock
            attributes={{
              align: "",
              anchor: "",
              className: "",
              content: "Enquiry",
              level: 1,
              backgroundColor: "",
              textColor: "primary",
              __typename: "WpCoreHeadingBlockAttributes",
            }}
            innerBlocks={[]}
          />
          <div className="enquiry-wrap">
            <div className="content">
              <Product product={product} group={this.state.group} />
            </div>
            <div className="form">
              <Form product={product} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    wpPage(uri: { eq: "/" }) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

export default withStyles(styles)(Page);
